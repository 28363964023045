<template>
    <v-radio
        class="background-radio"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template v-slot:label>
            <div class="background-radio__container">
                <div
                class="background-radio__percent-background"
                :style="`width: calc(${percent}%)`"
                ></div>
                <div class="background-radio__label">{{ label }}</div>
            </div>
        </template>
    </v-radio>
</template>

<script>
    export default {
        name: 'BackgroundRadio',
        inheritAttrs: false,
        props: {
            label: {
                type: String
            },
            percent: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style lang="scss">
    $red: #E2231A;

    .background-radio {
        width: 100%;
        height: 56px;
        border-radius: 12px;
        padding: 16px 23px;
        border: 1px #D5DAE2 solid;
        overflow: hidden;
        position: relative;
        .v-radio .background-radio .theme--light {
            position: relative;
        }
        .v-label {
            position: unset !important;
        }
        &__container {
            width: 110%;
            height: 56px;
            margin-left: -51px;
            // margin-right: -51px;
            // position: relative;
        }

        &__percent-background {
            position: absolute;
            height: 56px;
            background: #EDEDED;
            overflow: visible;
            z-index: 0;
        }
        &__label {
            position: absolute;
            padding-top: 18px;
            margin-left: 51px;
        }

        .v-input--selection-controls__input {
            // background: #fff;
            display: flex;
            justify-content: center;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            z-index: 2;
            @media all and (min-width: 768px) {
                margin-right: 10px;
            }
        }
        .v-input--selection-controls__ripple {
            display: none;
        }
        &.v-item--active {
            border: 1px #E61E4D solid;
            z-index: 2;
            .background-radio__percent-background {
                background: linear-gradient(270deg, rgba(216, 7, 101, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%);
            }
        }
        .v-item--active .v-icon.v-icon {
            width: 16px;
            height: 16px;
            background: $red;
            border-radius: 12px;
            top: 3px;
            left: 3px;
            position: absolute;
            color: $red;
            caret-color: $red;
        }
        .v-label {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #273155;
        }
    }
</style>
