<template>
    <v-select
        v-bind="$attrs"
        ref="select"
        class="quiz-select"
        :value="value"
        append-icon="mdi-chevron-down"
        outlined
        v-on="$listeners"
    >
        <template #append>
            <img src="../../../assets/DownArrow2.svg" class="base-select__append" />
        </template>
    </v-select>
</template>

<script>
    export default {
        name: 'quizSelect',
        inheritAttrs: false,
        props: {
            value: {
                type: [String, Array],
                default: ''
            }
        }
    }
</script>

<style lang="scss">
.quiz-select {
    position: relative;
    margin-bottom: 16px !important;
    height: 56px;

    .v-select__selection--comma {
        position: absolute;
        bottom: 15px;
        left: 8px;
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155 !important;
        @media all and (min-width: 768px) {
            font-size: 16px;
            bottom: 12px;
        }
    }

    .v-input__slot {
        border: 1px solid #EBEBEB;
        border-radius: 10px;
        background: #fff !important;
        box-shadow: rgba(30, 28, 46, 0.03);
        margin-bottom: 0;
        height: 56px;
    }
    .v-input__icon--append > .v-icon {
        color: #E22319 !important;
    }
    &__append {
        margin:  10px;
    }
    &.v-text-field--enclosed .v-input__append-inner {
        margin-top: 25px;
    }
    &.v-text-field--outlined.v-input--is-focused fieldset,
    &.v-text-field--outlined.v-input--has-state fieldset {
        border: 1px solid #000;
    }
    &.v-text-field--outlined.error--text.v-input--is-focused fieldset,
    &.v-text-field--outlined.error--text.v-input--has-state fieldset {
        border: 1px solid #ff5252;
    }
    &.v-text-field--outlined fieldset {
        border: none;
    }
    .v-text-field__details {
        min-height: 0;
        padding: 0 !important;
    }
    &.v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 0;
    }
    .v-messages.theme--light {
        min-height: 0;
    }
    &.v-text-field--outlined .v-label {
        margin-left: 8px;
        top: 18px;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        @media all and (min-width: 768px) {
            top: 16px;
        }
    }
    
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 56px;
        max-height: 56px;
    }

    &.v-text-field--outlined .v-label--active {
        display: none;
        top: 27px;
        @media all and (min-width: 768px) {
           top: 30px;
        }
        
    }

    .v-messages.theme--light.error--text {
        margin-top: 4px;
    }
    fieldset {
        display: none;
    }
}
</style>
