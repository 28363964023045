<template>
    <div class="trip-request" v-if="translates && translates[langUrl]">
        <div v-if="mainContainer" class="trip-request__main-container">
            <div class="trip-request__logo"></div>
            <ValidationObserver ref="validator" slim>
                <template v-if="!tripRequest.trip">
                    <h1 class="trip-request__page-name">{{ translates[langUrl].mainDirection[lang] }}</h1>
                    <ValidationProvider rules="required">
                        <v-radio-group
                            v-model="tripRequest.direction">
                            <template v-for="(item, index) in summary.directions">
                                <BackgroundRadio
                                    :key="index"
                                    :label="`${item.title} (${percent(item.value)}%)`"
                                    :value="item.code"
                                    :percent="backgroundPercent(item.value)"
                                />
                            </template>
                            <BackgroundRadio
                                :label="translates[langUrl].directionOther[lang]"
                                value="other"
                            />
                        </v-radio-group>
                        <QuizSelect
                            v-if="tripRequest.direction === 'other'"
                            :label="translates[langUrl].directionOther[lang]"
                            v-model="tripRequest.directionOther"
                            :items="filteredDirections"
                            item-text="title"
                            item-value="code"
                        />
                    </ValidationProvider>
                    <h1 class="trip-request__page-name">{{ translates[langUrl].directionAdditional[lang] }}</h1>
                    <ValidationProvider>
                        <v-radio-group
                            v-model="tripRequest.directionAdditional">
                            <template v-for="(item, index) in summary.directionsAdditional">
                                <BackgroundRadio
                                    :key="index"
                                    :label="`${item.title} (${percent(item.value)}%)`"
                                    :value="item.code"
                                    :percent="backgroundPercent(item.value)"
                                />
                            </template>
                            <BackgroundRadio
                                :label="translates[langUrl].directionOther[lang]"
                                value="other"
                            />
                        </v-radio-group>
                        <QuizSelect
                            v-if="tripRequest.directionAdditional === 'other'"
                            :label="translates[langUrl].directionOther[lang]"
                            v-model="tripRequest.directionAdditionalOther"
                            :items="filteredDirectionsAdditional"
                            item-text="title"
                            item-value="code"
                        />
                    </ValidationProvider>
                </template>
                <ValidationProvider rules="required">
                    <h1 class="trip-request__page-name">{{ translates[langUrl].duration[lang] }}</h1>
                    <v-radio-group
                        v-model="tripRequest.duration">
                        <template v-for="(item, index) in summary.durations">
                            <div :key="index">
                                <BackgroundRadio
                                    :label="`${item.title} (${percent(item.value)}%)`"
                                    :value="item.code"
                                    :percent="backgroundPercent(item.value)"
                                    @click="updateDurationIndex(index)"
                                />
                                <ValidationProvider rules="required">
                                    <template v-if="index === durationIndex">
                                        <BaseDatePicker
                                            :label="translates[langUrl].start[lang]"
                                            v-model="tripRequest.start"
                                            @change="updateDates"
                                        />
                                    </template>
                                </ValidationProvider>
                            </div>
                        </template>
                    </v-radio-group>
                </ValidationProvider>
                <ValidationProvider rules="required">
                    <h1 class="trip-request__page-name">{{ translates[langUrl].comfort[lang] }}</h1>
                    <div class="trip-request__comment">{{ translates[langUrl].comfort_comment[lang] }}</div>
                    <v-radio-group
                        v-model="tripRequest.comfort">
                        <template v-for="(item, index) in summary.comforts">
                            <BackgroundRadio
                                :key="index"
                                :label="`${item.title} (${percent(item.value)}%)`"
                                :value="item.code"
                                :percent="backgroundPercent(item.value)"
                            />
                        </template>
                    </v-radio-group>
                </ValidationProvider>
                <ValidationProvider>
                    <h1 class="trip-request__page-name">{{ translates[langUrl].activities[lang] }}</h1>
                    <v-container style="padding: 0">
                        <template v-for="(item, index) in sortedActivities.slice(0,6)">
                            <BackgroundCheckbox
                                v-model="tripRequest.activities"
                                :key="index"
                                :label="`${item.title} (${percent(item.value)}%)`"
                                :value="item.code"
                                :percent="backgroundPercent(item.value)"
                            />
                        </template>
                    </v-container>
                    <QuizMultipleSelect
                        :label="translates[langUrl].activitiesAdditional[lang]"
                        v-model="tripRequest.activities"
                        :items="sortedActivities.slice(6)"
                        item-text="title"
                        item-value="code"
                        multiple
                        persistent-hint
                    />
                </ValidationProvider>
                <h1 class="trip-request__page-name">{{ translates[langUrl].workshops[lang] }}</h1>
                <div class="trip-request__comment">{{ translates[langUrl].workshops_comment[lang] }}</div>
                <BaseTextArea 
                    :placeholder="translates[langUrl].workshops_text[lang]"
                    v-model="tripRequest.workshops"
                />
                <h1 class="trip-request__page-name">{{ translates[langUrl].final_text[lang] }}</h1>
                <div class="trip-request__comment">{{ translates[langUrl].final_comment[lang] }}</div>
            </ValidationObserver>
            <BaseButton
                class="trip-request__step-buttons__button button-fill"
                style="width: 100%"
                @click="save"
            >{{ translates[langUrl].button_save[lang] }}</BaseButton>
        </div>
        <div v-if="complete" class="trip-request__complete">
            <div class="trip-request__complete__container">
                <div class="trip-request__complete__check"></div>
                <div class="trip-request__complete__text">{{ translates[langUrl].complete_text[lang] }}</div>
                <BaseButton
                    @click="goTo('blogger-audience')"
                >{{ translates[langUrl].button_bloggerAudience[lang] }}</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, checkUserProfile, translit } from '@/helpers';

    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue';
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue';
    import QuizSelect from '../../components/common/quiz/QuizSelect.vue';
    import QuizMultipleSelect from '../../components/common/quiz/QuizMultipleSelect.vue';
    import QuizCheckbox from '../../components/common/quiz/QuizCheckbox.vue';
    import BackgroundCheckbox from '../../components/common/quiz/BackgroundCheckbox.vue';
    import BaseButton from '../../components/common/BaseButton.vue';
    import QuizButton from '../../components/common/quiz/QuizButton.vue';
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue';
    import QuizRadio from '../../components/common/quiz/QuizRadio.vue';
    import BackgroundRadio from '../../components/common/quiz/BackgroundRadio.vue';

    export default {
        name: 'TourRequest',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseTextField,
            BaseTextArea,
            BaseCheckbox,
            QuizSelect,
            QuizMultipleSelect,
            QuizCheckbox,
            BackgroundCheckbox,
            QuizButton,
            BaseButton,
            BaseDatePicker,
            QuizRadio,
            BackgroundRadio
        },
        data: () => ({
            imageSrc,
            mainContainer: true,
            complete: false,
            step: 1,
            errors: {
                pictures: ''
            },
            isDesktop: false,
            progress: 14,
            russia: false,
            tripRequest: {
                trip: null,
                blogger: '',
                direction: '',
                directionOther: '',
                directionAdditional: '',
                directionAdditionalOther: '',
                duration: '',
                start: '',
                comfort: '',
                activities: [],
                workshops: '',
            },
            loaded: false,
            durationIndex: null,
            langUrl: '/operator/trip-requests/id'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState('users', {
                blogger: state => state.entity
            }),
            ...mapState('quiz', ['dictionaries']),
            ...mapState('quiz', ['summary']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            sortedActivities() {
                return this.summary.activities ? this.summary.activities.sort((a, b) => b.value - a.value) : [];
            },
            filteredDirections() {
                return this.dictionaries.directions.filter(item => {
                    for(const d of this.summary.directions) {
                        if (d.code.toString() === item.code.toString()){
                            return false;
                        }
                    }
                    return true;
                });
            },
            filteredDirectionsAdditional() {
                return this.dictionaries.directionsAdditional.filter(item => {
                    for(const d of this.summary.directionsAdditional) {
                        if (d.code.toString() === item.code.toString()){
                            return false;
                        }
                    }
                    return true;
                });
            },
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await store.dispatch('quiz/summary', { qualified: true });
            if(this.$route.params.trip) {
                this.tripRequest.trip = this.$route.params.trip;
            }
            this.$root.$emit('preloaderHide');
        },
        methods: {
            loading() {
                this.loaded = true
            },
            percent(value) {
                return +(this.summary.qualified ? (value / this.summary.qualified) * 100 : 0).toFixed(0);
            },
            backgroundPercent(value) {
                return value ? (this.percent(value)) + 1 : 0;
            },
            label(item) {
                return `${item.title} (${item.label})`;
            },
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            completeQuiz() {
                this.mainContainer = false;
                this.complete = true;
            },
            async validate() {
                const valid = await this.$refs['validator'].validate();
                return valid;
            },
            clearServerErrors() {
                this.serverErrors = {
                    code: ''
                };
            },
            async save() {
                this.$root.$emit('preloaderShow');
                store.commit('tripRequests/SET_ENTITY', {
                    ...this.tripRequest
                });
                await store.dispatch('tripRequests/save');
                this.$root.$emit('preloaderHide');
                this.completeQuiz();
            },
            updateDates(value) {
                this.tripRequest.start = value;
            },
            updateDirection(value) {
                this.tripRequest.directionOther = value;
            },
            updateDirectionAdditional(value) {
                this.tripRequest.directionAdditionalOther = value;
            },
            updateDurationIndex(index) {
                this.durationIndex = index;
            }
        },
    };
</script>

<style lang="scss">
.trip-request {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    @media all and (max-width: 768px) {
        width: 100%;
    }
    &__logo {
        height: 30px;
        width: 260px;
        background: url(../../assets/Main-logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 60px;
        @media all and (max-width: 768px) {
            margin-bottom: 32px;
        }
    }
    &__tabs-outline {
        border-bottom: 1px solid #E5E6E9;
        margin: 32px 0 16px;
    }
    &__page-name {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0 12px;
        @media all and (max-width: 768px) {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__comment {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #616D89;
        @media all and (max-width: 768px) {
            font-weight: 600;
        }
    }
    &__description {
        font-family: 'Inter';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #616D89;
        margin-top: 40px;
    }

    &__buttons {
        &__other-direction {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #E61E4D;
            margin: 32px auto;
        }
    }

    &__start {
        height: 100vh;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            max-width: 445px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (max-width:768px) {
                max-width: 100%;
                padding: 20px;
            }
        }
        &__logo {
            height: 30px;
            width: 260px;
            background: url(../../assets/Main-logo.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 32px;
        }
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            text-align: center;
            color: #273155;
            margin-bottom: 60px;
        }
        &__button {
            width: calc(100% - 40px);
            margin-bottom: 16px;
            height: 60px !important;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #A1A4B1;
        }
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        // margin-bottom: 40px;
        width: 650px;
        height: 100%;
	    padding-top: 80px;
        padding-bottom: 160px;
        margin: 0 auto;
        @media all and (max-width: 768px) {
            width: 100%;
            padding: 20px 20px 160px;
        }
        &__items {
            width: 650px;
            height: 100%;
            // padding: 20px;
            // background: #FFFFFF;
            // box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            // border-radius: 12px;
            margin: 0 auto;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
        &__menu {
            width: 325px;
            max-height: 700px;
            margin-left: 32px;
            padding: 20px 4px;
            background: #F7F9FA;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 12px;
            overflow: hidden;
            &__item {
                display: flex;
                flex-direction: column;
                padding: 10px;
                list-style-type: none;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #525B77;
                transition-duration: 1s;
                cursor: pointer;
                &_active {
                    color: #fff;
                    background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
                    border-radius: 12px 0 0 12px;
                    // opacity: .8;
                    transition-duration: 1s;
                }
            }
        }
    }
    &__complete {
        height: 100vh;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        &__container {
            max-width: 445px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media all and (max-width:1024px) {
                padding:  0 40px;
            }
        }
        &__check {
            height: 100px;
            width: 100px;
            background: url(../../assets/quiz-check.svg);
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 32px;
        }
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            text-align: center;
            color: #273155;
            margin-bottom: 12px;
        }
        &__comment {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #A1A4B1;
            @media all and (max-width:768px) {
                font-size: 18px;
                line-height: 140%;
            }
        }
    }
    &__checkboxes-container {
        display: flex;
        flex-direction: row;
        @media all and (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__checkbox-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 305px;
        &.russian {
            margin-right: 40px;
        }
        &.wrap {
            width: 690px;
            margin-right: -40px;
            @media all and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
            }
        }
        @media all and (max-width: 768px) {
            width: 100%;
        }
    }

    &__personal-info {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        .base-checkbox.v-input--selection-controls {
            margin: 0 !important;
        }

        &__text {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: #616D89;
            text-align: left;
            @media all and (max-width: 768px) {
                font-weight: 300;
                font-size: 14px;
            }
            &__accent {
                font-weight: 500;
                color: black;
                text-decoration: underline;
            }
        }
    }
    &__step {
        height: 100%;
        padding-bottom: 20px;
        span {
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
    }
    &__step-buttons {
        // @media all and (min-width: 768px) {
        //         display: none;
        //     }
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        margin-top: 4px;
        width: 100%;
        &__button {
            width: 159px;
            height: 48px;
            margin-bottom: 12px;
            background: #FFFFFF;
            color: #DA0A63;
            &.moderate {
                min-width: 100% !important;
                @media all and (min-width: 768px) {
                    width: 280px;
                }
            }
            &__back {
                width: 180px;
                @media all and (max-width: 768px) {
                    width: 115px;
                }
            }
            &__next {
                width: 440px;
                @media all and (max-width: 768px) {
                    width: 195px;
                }
            }
        }
    }
    &__save {
        background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
        width: 100%;
        border: none !important;
    }
    &__desktop-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: fixed;
        bottom: 80px;
        width: 650px;
        padding-top: 20px;
        // background: #FFFFFF;
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        }
        &__rigth-container {
            display: flex;
            flex-direction: row;
            .v-btn {
                margin-left: 20px;
            }
        }
        &__save {
            width: 230px;
            background: linear-gradient(270deg, rgba(230, 30, 77, 0.1) 0%, rgba(230, 30, 77, 0.1) 100%), #FFFFFF;
            border: none !important;
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        padding: 8px 0 12px;
    }
    &__input-container{
        display: flex;
        min-height: 56px;
        width: 100%;
        &__line {
            display: flex;
            flex-direction: row;
        }
        &__input-name{
            display: none;
            @media all and (min-width: 768px) {
                display: flex;
                min-width: 185px;
                max-width: 185px;
                margin-right: 40px;
                color: #65676E;
                padding-top: 18px;
            }
        }
        &__input-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media all and (min-width: 768px) {
                flex-direction: row;
            }
            &__tour-type {
                width: 100%;
                flex-direction: column;
            }
            &__list {
                width: 100%;
                flex-direction: column;

                > div {
                    padding-bottom: 20px;    
                }
                .v-textarea.base-text-area {
                    margin-bottom: 5px !important;
                }
                .delete-button__container {
                    margin-top: 5px;
                }
            }
        }
    }
    &__form-card {
    padding-top: 28px;
        &:first-of-type {
            padding-top: 0;
        }
        &__delete-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        &__outline {
            border-bottom: 0.5px solid #E5E6E9;
            padding: 28px 0 4px;
            margin-bottom: 20px;
        }
    }
    &__radio-group {
        margin-top: 0;
        padding-bottom: 8px;
        @media all and (min-width: 768px) {
            margin-top: 10px;
            &.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0px;
            }
        }
        
    }
    &__span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        letter-spacing: -0.02em;
        color: #A2A5AE;
        margin-left: 3px;
    }
    &__info {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        @media all and (min-width: 768px) {
            margin-left: 225px;
        }

        &__icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        &__text {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #A2A5AE;
            max-width: 79%;
        }
        p {
            margin: 0;
        }
    }
    &__progress {
        position: fixed;
        width: 650px;
        bottom: 0;
        height: 80px;
        padding: 16px 0 32px;
        // background: #FFFFFF;
        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
        }
    }
    &__progress-percent {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 13px;
        color: #273155;
        margin-bottom: 12px;
    }
    &__progress-bar {
        height: 6px !important;
        border-radius: 3px;
        .v-progress-linear__buffer {
            height: 6px;
        }
    }
    .buttons {
	display: flex;
	flex-direction: column;
    }
}
</style>